import React                 from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { makeStyles }        from 'makeStyles';
import { userInfoType }      from 'types';

const arr = [
    `No credis card details needed`,
    `Access all of I See Math's apps, puzzles and games`,
    `Create and save your own lessons`
]

const useStyles = makeStyles()(
  (Theme) => ({    root: {
       width:          '80%',
       marginLeft:     '10%',
       flex:           0.8,
       display:        'flex',
       alignItems:     'center',
       justifyContent: 'center'
    },
    column: {
       flex:           1,
       display:        'flex',
       alignItems:     'center',
       justifyContent: 'celter',
       flexFlow:       'column',
       width:           '100%',
    },
    planTitle: {
       textAlign:     'center',
       fontWeight:    'bold',
       fontSize:      21,
       paddingBottom: 20
    },
    plan: {
     //  fontWeight:   'bold',
       textAlign:  'center',
       fontSize:     16,
       lineHeight:   1.5
    },
    list: {
        marginTop: 25,
        display:   'flex',
        flexFlow:  'column',
    },
    item: {
        display:        'flex',
        alignItems:     'center',
        justifyContent: 'celter',
        height:         60,
        width:          '100%',
    },
    icon: {
        fontSize:     10,
        paddingRight: 8
    }
}))

type PitchProps = { userInfo: userInfoType; }

const Pitch: React.FC<PitchProps> = ({ userInfo }) => {

  const { classes } = useStyles();

  const amount = React.useMemo(() => {
    return userInfo.countryName === 'United Kingdom' ? ['£9.95', '£8.95'] : ['$12.95', '$10.95']
  },[userInfo])

  return (
    <div className = { classes.root }>
         <div className = { classes.column }>
             <div className = { classes.planTitle }>
                    Try I See Math for free for 30 days.
            </div>
            <div className = { classes.plan }>
                { `After 30 days pay ${ amount[0] } a month for a monthly subscription or ${ amount[1] } a month for an annual subscription.` }
            </div>
            <div className = { classes.plan }>
                   Cancel anytime.
            </div>  
            <div className = { classes.list }>
             {
                 arr.map((val) => {
                     return(
                        <div 
                          key       = { `${ val }` }
                          className = { classes.item }
                        > 
                           <FiberManualRecordIcon style = {{ fontSize: 10, paddingRight: 8 }}/>
                           <div>{ val }</div>
                        </div>
                     )
                 })
             }
            </div>
         </div>
    </div>   
  );
}



export default Pitch;





