import React, { Suspense }     from 'react';
import { Routes, Route }       from "react-router-dom";
import { Dispatch }            from "redux";
import { ActionTypes }         from 'actions/types';
import Loader                  from 'components/Loader';
import AppIntro                from 'components/app_intro';
import AppIframe               from 'components/appIframe';
import { connect }             from 'react-redux';
import { reducerType }         from 'reducer';

import { 
          appTopicsArrType,
          contentInfoType,
          pageStateType,
          pdfStateType,
          userInfoType
       }                       from 'types'
import { colors }              from 'utils/colors';
import { sizes }               from 'utils/defaultStates';
import { makeStyles }          from 'makeStyles';
import { componentsStateType } from 'reducer/types';
import usePath                 from './usePath'
import useShowGrid             from './useShowGrid'
import { SELECT_CONTENT }      from 'actions/content_actions';
 
const Grid = React.lazy(() => import('./Grid'));
 

const useStyles = makeStyles()(
  (Theme) => ({    
      root: {
      position:        'absolute',
      left:            0,
      top:             0,
      height:          '100vh',
      width:           '100vw',
      backgroundColor: colors.brightGrey,
    },
    fallBack: {
      position:        'absolute',
      right:           0,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: 'transparent',
    }
}))

type ContentGridProps = {
  appTopicsArr:          appTopicsArrType[];
  userInfo:              userInfoType;
  allContent:            contentInfoType[];
  componentsState:       componentsStateType;
  size:                  number[];
  menuSize:              number;
  wideScreen:            boolean;
  showLoader:            boolean;
  showUpdatingLoader:    boolean;
  activityUpdated:       boolean;
  denyAccess:            boolean;
  studentLoggedIn:       boolean;
  showHelp:              boolean;
  showSCLoader:          boolean;
  studentOpenApps:       string[];
  freeApp:               string;
  selectedApp:           contentInfoType;
  PDFState:              pdfStateType;
  pageState:             pageStateType;
  AudioRef:              any;
  setPDFState:           (val: pdfStateType) => void;
  setChangeContent:      (val: boolean) => void;
  setSelectedApp:        (val: contentInfoType) => void;
  selectContent:         (val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => void;
  addStudentOpenApps:    (val: contentInfoType) => void;
  removeStudentOpenApps: (val: contentInfoType) => void;
}

const ContentGrid: React.FC<ContentGridProps> = ({
  appTopicsArr,
  userInfo,
  allContent,
  componentsState,
  size,
  menuSize,
  wideScreen,
  showLoader,
  showUpdatingLoader,
  showHelp,
  showSCLoader,
  selectedApp,
  activityUpdated,
  denyAccess,
  pageState,
  studentOpenApps,
  freeApp,
  studentLoggedIn,
  PDFState,
  AudioRef,
  setPDFState,
  setChangeContent,
  setSelectedApp,
  selectContent,
  addStudentOpenApps,
  removeStudentOpenApps  
}) => {

  const { classes } = useStyles();

  const [gridArr, setGridArr] = React.useState([] as contentInfoType[]);
 
  React.useEffect(() => {
      if ( !!pageState.opacity && pageState.page === 'content' ) {
        setGridArr(allContent.filter((val) => { return(!!val.lesson_id || val.uid) }))
      }
  },[allContent, pageState])

 
  const path = usePath(componentsState, selectedApp)

  const { 
    showGrid, 
    showAppIntro, 
    displayLoader
  } = useShowGrid(componentsState, showLoader, path, gridArr)
  
  console.log('showGrid      : ',showGrid)
  console.log('displayLoader : ',displayLoader)
  
  return (
         <div className = { classes.root }> 
            { 
              showGrid
              &&
              <Suspense 
                fallback = {
                  <div 
                    className = { classes.fallBack }
                    style     = {{
                      top:    sizes.navBarSize,
                      width:  size[0] - menuSize,
                      height: size[1] - sizes.navBarSize - sizes.footerSize
                    }}
                  >
                    <Loader/>
                  </div>
                }
              >
                  <Grid
                    appTopicsArr          = { appTopicsArr }
                    componentsState       = { componentsState }
                    userInfo              = { userInfo }
                    studentOpenApps       = { studentOpenApps }
                    gridArr               = { gridArr }
                    size                  = { size }
                    freeApp               = { freeApp }
                    page                  = { pageState.page }
                    showHelp              = { showHelp }
                    showSCLoader          = { showSCLoader }
                    denyAccess            = { denyAccess }
                    studentLoggedIn       = { studentLoggedIn }
                    menuSize              = { menuSize }
                    wideScreen            = { wideScreen }
                    activityUpdated       = { activityUpdated }
                    showUpdatingLoader    = { showUpdatingLoader }
                    setChangeContent      = { setChangeContent }
                    selectContent         = { selectContent }
                    addStudentOpenApps    = { addStudentOpenApps }
                    removeStudentOpenApps = { removeStudentOpenApps }
                  />
                </Suspense> 
            }
            {
              displayLoader
              &&
              <div 
                    className = { classes.fallBack }
                    style     = {{
                      width:  wideScreen ? size[0] - menuSize : size[0],
                      height: size[1] - sizes.navBarSize - sizes.footerSize,
                    }}
              >
                <Loader/>
              </div>
            }
            <AppIframe
                size           = { size }
                selectedApp    = { selectedApp }
                guest          = { denyAccess }
                setSelectedApp = { setSelectedApp }
            />
            {
              showAppIntro
              &&
              <Routes>
                <Route 
                  path    = { path }
                  element = {
                    <AppIntro
                      app              = { selectedApp }
                      size             = { size }
                      menuSize         = { menuSize }
                      wideScreen       = { wideScreen }
                      denyAccess       = { denyAccess }
                      freeApp          = { freeApp }
                      activityUpdated  = { activityUpdated }
                      studentLoggedIn  = { studentLoggedIn }
                      PDFState         = { PDFState }
                      setChangeContent = { setChangeContent }
                      selectContent    = { selectContent }
                      setPDFState      = { setPDFState }
                    />   
                  }
                />
                <Route 
                  path    = { '/' }
                  element = { <div/> }
                />
              </Routes>
            }        
         </div>
  );
}




const mapStateToProps = (reducer: reducerType) => {
  return {
    componentsState: reducer.components,
    pageState:       reducer.page,
    allContent:      reducer.content.allContent,
    userInfo:        reducer.user.userInfo,
    selectedApp:     reducer.content.selectedApp,
}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
    setSelectedApp: (payload: contentInfoType) => dispatch(SELECT_CONTENT(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ContentGrid);

