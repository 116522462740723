import React  from 'react';
import { useNavigate }      from 'react-router-dom'
import Unit                 from './Unit';
//import LoaderSmall          from 'components/LoaderSmall';
import { topics }           from 'utils/defaultStates';
import { topicClick }       from 'functions/menu/topic_click';
import recordEvent          from 'functions/contentInteraction/record_event';
import { words }            from 'utils/dictionary';
import { sizes }            from 'utils/defaultStates';
import { makeStyles }       from 'makeStyles';
import { appTopicsArrType } from 'types';




const topicArr = Object.keys(topics)

const backgroundColor = '#F8F8F9';

const useStyles = makeStyles()(
  (Theme) => ({    
    container: {
      position:        'absolute',
      right:           0,
      overflowY:       'scroll',
      overflowX:       'hidden',
      backgroundColor: backgroundColor,
      '&::-webkit-scrollbar': {
        borderLeft: '1px solid #CCC',
        width:      '0.5em',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: `#BBB`,
        borderRadius:    10
      }
    },
    grid: {
      display:    'grid',
      marginLeft: '50%',
      width:      '100%',
    },
    fallBack: {
      height:          320,
      width:           300,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: 'transparent',
    },
    loaderContainer: {
      height:          290,
      width:           270,
      border:          `1px solid #CCC`,
      borderRadius:    '0.5em',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: '#FFF',
    },
    warning: {
      //marginTop:       15,
      height:          90,
      width:           '100%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      color:           '#666',
      fontSize:        20,
    //  fontWeight:      'bold',
      backgroundColor: 'transparent',
      transition:      'all 0.4s'
    },
    updating: {
      position:        'fixed',
      right:           0,
      height:          60,
      width:           '100%',
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      textAlign:       'center',
      color:           '#666',
      fontSize:        17,
      //fontWeight:      'bold',
      //backgroundColor: 'transparent'
      backgroundColor: 'transparent',
      zIndex:           1,

    },
    smallLoaderContainer: {
      marginLeft: 15
    }
}))

type GridProps = {
  size:             number[];
  menuSize:         number;
  page:             string;
  wideScreen:       boolean;
  guest:            boolean;
  appTopicsArr:     appTopicsArrType[];
  setChangeContent: (val: boolean) => void;
}

const Grid: React.FC<GridProps> = ({
  size,
  menuSize,
  page,
  wideScreen,
  appTopicsArr,
  guest,
  setChangeContent,
}) => {
  
  const { classes } = useStyles();
  const navigate    = useNavigate();


  const [gridOpacity, setgridOpacity] = React.useState(1)

   React.useEffect(() => {

    setgridOpacity(0)

    const timeOut = setTimeout(() => { 
      setgridOpacity(1)
    },200)

    return () => { clearTimeout(timeOut) }

  },[]) 
   

  // const generatedTopics = React.useMemo(() => {

  //   let arr = []

  //   for (let i = 0; i < appTopicsArr.length; i++) {
  //       arr.push(words[appTopicsArr[i].mainTopic]['eng'])
  //    }
  //    return arr

  //  },[appTopicsArr]) 



  const handleTopicClick = ( val: string ) => {
    navigate(`../${ val }`);
    topicClick( 'topic', topics[val], setChangeContent)
    recordEvent('navigation button', 'apps topic selected', words[`${ topics[val] }`]['eng'])
  }




  const containerStyle = React.useMemo(() => {

    const { navBarSize, tabSize, footerSize } = sizes;

    return {
      top:        navBarSize + tabSize,
      height:     size[1] - navBarSize - tabSize - footerSize,
      width:      wideScreen ? size[0] - menuSize : size[0],
    }
  },[size, wideScreen, menuSize])


  const gridStyle = React.useMemo(() => {

    const { navBarSize, tabSize, footerSize } = sizes;
    return {
      marginTop:           size[1] > (640 + navBarSize + tabSize + footerSize) ? ((size[1] - navBarSize - tabSize - footerSize - 640) / 2) : 0,
      transform:           `translate(-50%, 0)`,
      gridTemplateColumns: `repeat(${ size[0] > 1250 ? 2 : ( size[0] > 950 ? 2 : 1) }, 1fr)`,
      maxWidth:            size[0] > 1250 ? 930 : 620,
    }
  },[size])

 // console.log('generatedTopics : ',generatedTopics)
 // console.log('topicArr :        ',  topicArr)


  return (
         <div 
            className = { classes.container }
            style     = { containerStyle }
         >
           
            <div 
               className = { classes.grid }
               style     = { gridStyle }
            >
            {
               ['integers1', 'geometry2', 'fractions', 'axis2'].map((val, ind) => {

                    return(
                           <Unit
                              key         = { `${ val }${ ind }` }
                              topic       = { topicArr[ind] }
                              image       = { `url(https://iseemath.co/images/${ val }.png)` }
                              guest       = { guest }
                              gridOpacity = { gridOpacity }
                              disable     = { false }
                              onClick     = { handleTopicClick }
                          />
                    )
                })
            }
            </div>
            {/* {
             !generatedTopics.length && page === 'content' &&
             <div 
                className = { classes.updating }
                style     = {{
                  top:   sizes.navBarSize * 1,
                  width: containerStyle.width,
                }}
             >
                <div> Loading </div>
                <div className = { classes.smallLoaderContainer }>
                    <LoaderSmall size = { 20 } color = { '#666' }/>
                </div>
             </div>
           } */}
        </div>
  );
}

export default Grid;





