import React                          from 'react';
import { Routes, Route }              from "react-router-dom";
import { Dispatch }                   from "redux";
import { connect }                    from 'react-redux';
import ContentGrid                    from './content_grid';
import BonusGrid                      from './bonus_grid';
import UserLessonsGrid                from './user_lessons_grid';
import AppTopicsGrid                  from './appTopicGrid';

import updateStdntOpenApps            from 'functions/contentInteraction/update_student_open_apps';

import { reducerType }                from 'reducer';
import { ActionTypes }                from 'actions/types';
import { SET_STUDENT_OPEN_APPS_SAGA } from 'actions'
import { SET_MODAL_STATE }            from 'actions/modal_actions'

import { 
    appTopicsArrType,
    contentInfoType, 
    pageStateType, 
    pdfStateType, 
    userInfoType 
}                                    from 'types';
import { modalStateType }            from 'actions/types';
import { componentsStateType }       from 'reducer/types';
  

type GridsProps = {
  userInfo:              userInfoType;
  myStudentContent:      { [key: string]: any }[];
  selectedApp:           { [key: string]: any }[];
  allContent:            contentInfoType[];
  componentsState:       componentsStateType;
  appTopicsArr:          appTopicsArrType[];
  size:                  number[];
  menuSize:              number;
  wideScreen:            boolean;
  showLoader:            boolean;
  showUpdatingLoader:    boolean;
  activityUpdated:       boolean;
  denyAccess:            boolean;
  studentLoggedIn:       boolean;
  freeApp:               string;
  subscriptionStatus:    string;
  PDFState:              pdfStateType;
  pageState:             pageStateType;
  AudioRef:              any;
  setChangeContent:      (val: boolean) => void;
  selectContent:         (val: contentInfoType, destination: string, disabled: boolean, disabledFadeIn: boolean) => void;
  setPDFState:           (val: pdfStateType) => void;
  setModalState:         (payload: modalStateType) => void,
  setStudentOpenApps:    (payload: { userEmail: string, arr: string, myStudentContent: contentInfoType[] }) => void;
}

const Grids: React.FC<GridsProps> = ({
    userInfo,       
    selectedApp,  
    myStudentContent,  
    appTopicsArr,
    allContent,     
    componentsState,    
    size,               
    menuSize,           
    wideScreen,         
    showLoader,         
    showUpdatingLoader, 
    activityUpdated,    
    denyAccess,         
    studentLoggedIn,   
    freeApp, 
    pageState,           
    subscriptionStatus,
    PDFState,
    AudioRef,
    setPDFState,
    setChangeContent,
    setModalState,    
    selectContent,        
    setStudentOpenApps
}) => {


 // const location = useLocation();


  const [showContentGrid, showBonusGrid, showUserLessonsGrid] = React.useMemo(() => {

    const { page }            = pageState;
    const { selectedContent } = componentsState;

    return[
      (
        // location.pathname !== '/Apps' &&
         (selectedContent !== 'groupLessons' && selectedContent !== 'userLessons') && 
         (page === 'content' || page === 'teacherContent' || page === 'app page')
      ),
      (selectedContent === 'bonus'),
      (selectedContent === 'groupLessons' || selectedContent === 'userLessons')
    ]
  },[pageState, componentsState/* , location */])


  const [showHelp, showSCLoader] = React.useMemo(() => {

    const selected = componentsState.selectedContent;

    const MSCInstructions        = selected === 'myStudentsContent';
    const groupIntructions       = selected === 'groupLessons';
    const userLessonsIntructions = selected === 'userLessons';
    const lessonsIntructions     = selected === 'lessons';
    const favInstructions        = selected === 'favorites';
    const appsIntructions        = selected === 'apps' && !studentLoggedIn;

    return[
      ((!showLoader && !showUpdatingLoader) && (appsIntructions || MSCInstructions || groupIntructions || userLessonsIntructions || lessonsIntructions || favInstructions)),
      MSCInstructions && userInfo.teacherCode === '0'
    ]

  },[userInfo, showLoader, showUpdatingLoader, studentLoggedIn,  componentsState.selectedContent])




   const addStudentOpenApps = (val: contentInfoType) => {

    if ( userInfo.studentOpenApps.length >= 5 ) {
      setModalState({ variant: 'maxStudentOpenApps', open: true, showButtons: true })
      return;
    }

    updateStudentOpenApps('add', val )
  }


  const removeStudentOpenApps = (val: contentInfoType) => {
    updateStudentOpenApps('remove', val )
  } 


  const updateStudentOpenApps = (action: 'add' | 'remove', app: contentInfoType) => {
    updateStdntOpenApps(action, app, myStudentContent, userInfo.studentOpenApps, userInfo.userEmail, setStudentOpenApps);
  } 
 
  return (
    <>
         {
              showContentGrid
              &&
              <ContentGrid
                  studentOpenApps       = { userInfo.studentOpenApps }
                  appTopicsArr          = { appTopicsArr }
                  size                  = { size }
                  wideScreen            = { wideScreen }
                  menuSize              = { menuSize }
                  freeApp               = { freeApp }
                  activityUpdated       = { activityUpdated }
                  showLoader            = { showLoader } 
                  showUpdatingLoader    = { showUpdatingLoader }
                  denyAccess            = { denyAccess }
                  studentLoggedIn       = { studentLoggedIn }
                  PDFState              = { PDFState }
                  showHelp              = { showHelp }
                  showSCLoader          = { showSCLoader }
                  AudioRef              = { AudioRef }
                  setPDFState           = { setPDFState }
                  setChangeContent      = { setChangeContent }          
                  selectContent         = { selectContent }
                  addStudentOpenApps    = { addStudentOpenApps }
                  removeStudentOpenApps = { removeStudentOpenApps }
              />
          } 
          {
              showBonusGrid
              && 
              <BonusGrid
                  size             = { size }
                  guest            = { denyAccess }
                  menuSize         = { menuSize }
                  wideScreen       = { wideScreen }
                  setChangeContent = { setChangeContent }
                  selectContent    = { selectContent }
              />
          }
          {
              showUserLessonsGrid
              &&
              <UserLessonsGrid 
                  allContent            = { allContent }
                  size                  = { size }
                  userInfo              = { userInfo }     
                  page                  = { pageState.page }
                  wideScreen            = { wideScreen }
                  menuSize              = { menuSize }
                  showLoader            = { showLoader } 
                  studentOpenApps       = { userInfo.studentOpenApps }
                  showHelp              = { showHelp }
                  denyAccess            = { denyAccess }
                  selectContent         = { selectContent }
                  addStudentOpenApps    = { addStudentOpenApps }
                  removeStudentOpenApps = { removeStudentOpenApps }
              />
              
          }
          <Routes>
                <Route 
                  path    = { '/Apps' }
                  element = {
                    <AppTopicsGrid
                      size             = { size }
                      guest            = { denyAccess }
                      page             = { pageState.page }
                      menuSize         = { menuSize }
                      wideScreen       = { wideScreen }
                      appTopicsArr     = { appTopicsArr }
                      setChangeContent = { setChangeContent }
                    />
                  }
                />
                <Route 
                  path    = { '/' }
                  element = { <div/> }
                />
          </Routes>
    </>
  );
}





const mapStateToProps = (reducer: reducerType) => {

  return {
    hash:              reducer.content.hash,
    myStudentContent:  reducer.content.myStudentContent,
    selectedApp:       reducer.content.selectedApp,

    userInfo:          reducer.user.userInfo,
    studentLoggedIn:   reducer.user.studentLoginState.loginState === 'success',

    activityUpdated:   reducer.data.dataUpdateState.final,

    componentsState:   reducer.components,

    pageState:         reducer.page

}};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  setModalState:      (payload: modalStateType) => dispatch(SET_MODAL_STATE(payload)),
 // recordEvent:        (payload: recordEventType) => dispatch(RECORD_EVENT_SAGA(payload)),
  setStudentOpenApps: (payload: { userEmail: string, arr: string, myStudentContent: contentInfoType[] }) => dispatch(SET_STUDENT_OPEN_APPS_SAGA(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Grids);

