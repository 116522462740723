

import get_favorites           from '../userData/get_favorites';
import { 
         contentInfoType
}                              from '../../types';

import { componentsStateType } from '../../reducer/types'
import { store }            from 'index';
import { SET_MODAL_STATE } from 'actions/modal_actions'



const favoritesClick = (
    wideScreen:         boolean,
    componentsState:    componentsStateType,
    setShowSmallMenu:   (val: boolean) => void,
    setComponentsState: (val: componentsStateType) => void,
    setContent:         (payload: contentInfoType) => void,
) => {

if ( componentsState.selectedContent === 'favorites' ) {
  return
}


    const setModalState = (payload: { variant: string, open: boolean, showButtons: boolean }) => store.dispatch(SET_MODAL_STATE(payload))



    setComponentsState({
      ...componentsState,
      selectedGrades:           '1234567',
      selectedAppTopic:         '',
      selectedAppSubTopic:      '',
      searchStr:                '',
      selectedContent:          'favorites',
      showTutorial:             false,
      showStudentVideo:         false,
      selectedVideo:            -1,
      openGradeGrid:            false,
      showAppIntro:             false,
      showTopicIntro:           false,
      createTopicArr:           false
    })

    const arr = get_favorites()

    if (!arr.length) {
       setModalState({ variant: 'help', open: true, showButtons: true }) 
    }
  
    setContent({ allContent: arr })

    if (!wideScreen) {
      setShowSmallMenu(false)
    } 
  
} 

export default favoritesClick;